import React from "react";
import "./AboutTheBookSection.css";
import bookCover from "./coverPhoto.jpg";
import OrderButton from "./OrderButton";
import SectionHeader from "./SectionHeader";

const AboutTheBookSection = () => {
    return (
        <div className="AboutTheBookSection">
            <div className="aboutTheBookSectionBackground">
                <div className="aboutTheBookSectionContainer">
                    <img className="aboutTheBookSectionCover" src={bookCover} alt="Not Bad for a South Texas Boy book cover" />
                    <div className="aboutTheBookSectionTextContainer">
                        <h2 className="aboutTheBookTitleHeader">
                            “...Not Bad for a South Texas Boy”
                        </h2>
                        <div className="aboutTheBookMobileHeader">
                            <SectionHeader>"...Not Bad for a South Texas Boy"</SectionHeader>
                        </div>
                        <p className="bookDetails">
                            The title, “Not Bad for a South Texas Boy”, comes from a note penned by Federico Peña’s
                            college English professor. While first read as encouraging, it took years to understand
                            the nuanced message of low expectations for one of the University of Texas’ few students
                            of color. From modest beginnings in South Texas, through perseverance Peña would earn a
                            law degree; fight for social justice as a civil rights attorney; serve as a Colorado
                            Legislator and Denver’s Mayor; and become our nation’s Secretary of Transportation and
                            Secretary of Energy.
                        </p>
                        <p className="bookDetails">
                            Describing a challenging life journey from the small border town of Brownsville, Peña
                            details his transformation from his original dream of practicing conventional law, to a
                            passion for fighting for the underserved. He discovers a rekindled hope in America by
                            lobbying for education reforms in Colorado and then serendipitously becomes a state
                            legislator. Surprising political pundits, he is elected as Mayor of Denver where he
                            blazes through a calamitous recession, builds a new international airport and modern
                            convention center. He invests in neighborhoods, preserves historic buildings, and brings
                            major league baseball to Colorado.
                        </p>
                        <p className="bookDetails">
                            As Transportation Secretary, he helps restore Los Angeles from its 1994 devastating earthquake,
                            introduces new transportation safety standards and technological advances, and supports significant
                            infrastructure investments. As Energy Secretary, he fashions a national energy policy, invests in
                            clean energy technologies, and travels the world fighting for America’s interests. He becomes the
                            first Latino to lead two federal departments in our nation’s capital.
                        </p>
                        <p className="bookDetails">
                            Today, Peña, a successful businessman, remains engaged in local, national and civic affairs and is highly sought for advice and leadership.
                        </p>
                        <p className="bookDetails">
                            Peña aims to inspire Americans who have lost faith in our country through his life’s challenges and his regained optimism for America. He provides guidance for our nation’s leaders with long-term strategies for the 21st Century.
                        </p>
                        <p className="bookDetails">
                            Ultimately, he believes that if a boy from South Texas can make a difference…so can you.
                        </p>
                        <div className="buttonContainer">
                            <OrderButton />
                        </div>
                        <img className="aboutTheBookSectionCoverMobile" src={bookCover} alt="Not Bad for a South Texas Boy book cover" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutTheBookSection;
