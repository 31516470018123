import React from "react";
import "./OrderButton.css";

const OrderButton = () => {
    return (
        <a
            target="_blank"
            rel="noreferrer noopener"
            className="OrderButton"
            href="https://www.amazon.com/Not-bad-South-Texas-boy/dp/0578925826/ref=sr_1_2?crid=Q6ALIO7APDHY&keywords=not+bad+for+a+south+texas+boy&qid=1636864276&sprefix=not+bad+for+a+south%2Caps%2C186&sr=8-2"
        >
            Order the book
        </a>
    );
}

export default OrderButton;
