import React from "react";
import "./AboutSection.css";
import SectionHeader from "./SectionHeader";

const AboutSection = () => {
    return (
        <div className="AboutSection">
            <div className="aboutHeaderContainer">
                <SectionHeader fontColor="white">About the Author</SectionHeader>
            </div>
            <p className="aboutText">
                Federico Peña has enjoyed success in law, politics and business. Born and raised in a South Texas
                border town, he has made Denver his home for nearly fifty years. Elected Mayor in 1983 during a
                disastrous recession, he managed to revitalize Denver with his vision of Imagine a Great City.
                He is credited with building Denver International Airport, bringing Major League Baseball's Colorado
                Rockies to Denver and transforming Denver's Platte Valley and transforming 32 blocks into historic
                LoDo, the thriving urban mecca of housing and business that it is today. Federico served in two
                cabinet positions under President Bill Clinton, in Transportation and Energy and later as a Co-Chair
                of the Obama for President Campaign in 2008. Returning to Denver from Washington DC he has worked in
                private equity and impact investing and has served on numerous corporate and non-profit boards. With
                roots as a Civil Rights attorney, Federico continues to work for systemic change in education. He
                is a loving husband, father, and grandfather and a grateful to be an adopted son of the great State
                of Colorado.
            </p>
        </div>
    );
}

export default AboutSection;
