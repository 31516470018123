import React from "react";
import "./BuyBookSection.css";
import bookCover from "./coverPhoto.jpg";
import OrderButton from "./OrderButton";

const BuyBookSection = () => {
    return (
        <div className="BuyBookSection">
            <div className="background">
                <div className="container">
                    <img className="bookCover" src={bookCover} alt="Not Bad for a South Texas Boy book cover" />
                    <div className="textContainer">
                        <h2 className="bookTitle">
                            "...Not Bad for a South Texas Boy"
                        </h2>
                        <h3 className="nowAvailable">
                            Now Available
                        </h3>
                        <OrderButton />
                    </div>
                    <div className="mobileOrderButton">
                        <OrderButton />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BuyBookSection;
