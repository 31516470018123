import React from "react";
import "./PraiseSection.css";
import SimpleSlider from "./SimpleSlider";
import SectionHeader from "./SectionHeader";

const PraiseSection = () => {
    return (
        <div className="PraiseSection">
            <div className="headerContainer">
                <SectionHeader>Praise for "...Not Bad for a South Texas Boy"</SectionHeader>
            </div>
            <div className="sliderContainer">
                <SimpleSlider />
            </div>
        </div>
    );
}

export default PraiseSection;
