import React from "react";
import "./SectionHeader.css";
import cn from "classnames";
import lineSVG from "./line.svg";

const SectionHeader = ({ fontColor, children }) => {
    return (
        <div className="SectionHeader">
            <h2 className={cn("headerText", fontColor === "white" ? "whiteFont" : "darkFont")}>
                {children}
            </h2>
            <img
                src={lineSVG}
                alt=""
                className="lineSVG"
            />
        </div>
    );
};

export default SectionHeader;
