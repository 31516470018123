import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import './SimpleSlider.css';
import SwiperCore, {
    Keyboard,Pagination,Navigation
} from 'swiper';

SwiperCore.use([Keyboard,Pagination,Navigation]);

export default function SimpleSlider() {

    return (
        <div className="SimpleSlider">
            <Swiper
                loop={true}
                slidesPerView={1}
                centeredSlides
                navigation={true}
                keyboard={{
                    "enabled": true
                }}
            >
                <SwiperSlide>
                    <figure>
                        <blockquote>
                            <p className="quote">
                                “One of the finest public servants it has been my pleasure to know, Federico Peña has a remarkable story to
                                tell and he tells it with candor, humility, and wisdom…As one of America’s most distinguished Hispanic leaders,
                                he has been instrumental in bringing the wider Hispanic community into the mainstream of American life. Federico
                                Peña’s life is an inspiration to us all and a challenge to future generations to ask what they can do for their
                                country.”
                            </p>
                        </blockquote>
                        <figcaption>—Senator and Presidential candidate Gary Hart</figcaption>
                    </figure>
                </SwiperSlide>
                <SwiperSlide>
                    <figure>
                        <blockquote>
                            <p className="quote">
                                “Federico’s story is America’s story—the continuing capacity of our nation to generate great leaders from all
                                walks of life, whose integrity, wisdom, and passion to the common good makes America stronger…Federico Peña has
                                a treasure trove of insights about how to provide opportunity for all, rebuild our economy, and restore upward
                                mobility…A powerful memoir and even more powerful assessment of where we are and where we can be.”
                            </p>
                        </blockquote>
                        <figcaption>—Robert Reich, Secretary of Labor, Chancellor’s Professor, University of California at Berkeley</figcaption>
                    </figure>
                </SwiperSlide>
                <SwiperSlide>
                    <figure>
                        <blockquote>
                            <p className="quote">
                                “Federico’s book is the story of an amazing self-starter. Born in Texas he was soon a middle child as triplets
                                were born after him. He powered on with his big heart, dreams and mind to become the kind of leader America so
                                desperately needs.”
                            </p>
                        </blockquote>
                        <figcaption>—Former Congresswoman Pat Schroeder</figcaption>
                    </figure>
                </SwiperSlide>
                <SwiperSlide>
                    <figure>
                        <blockquote>
                            <p className="quote">
                                “Federico Peña has over the years of his life contributed immensely to the public
                                interest. As Mayor of Denver, he completed projects which had only been
                                wished and proposals for decades. As a Cabinet officer he led two departments critical
                                to domestic priorities – transportation and energy. This autobiography masterfully describes
                                how he succeeded while continuously retaining his respect for public values, his approachable
                                manner, his genuine sincerity, and his resolute integrity. Some public figures jettison their
                                basic humanity in their drive to get things done. Federico's life story proves that a leader can be
                                both genuinely decent and epically effective.”
                            </p>
                        </blockquote>
                        <figcaption>—Henry Cisneros, Secretary of Housing and Urban Development</figcaption>
                    </figure>
                </SwiperSlide>
            </Swiper>
        </div>
    );
}
