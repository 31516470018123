import React from "react";
import "./ContactSection.css";
import SectionHeader from "./SectionHeader";
import { SocialIcon } from 'react-social-icons';

const ContactSection = () => {
    return (
        <div className="ContactSection">
            <div className="contactHeaderContainer">
                <SectionHeader>Connect</SectionHeader>
            </div>
            <div className="socials">
                <SocialIcon url="https://www.facebook.com/Federico-Pena-Author-104243892200775" />
                <SocialIcon url="https://www.linkedin.com/in/federico-pena-302397231/" />
                <SocialIcon url="mailto:FPena@ColoradoImpactFund.com?subject=FedericoPena.com Contact" />
            </div>
        </div>
    );
}

export default ContactSection;
