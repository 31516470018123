import React from "react";
import BuyBookSection from "./BuyBookSection";
import PraiseSection from "./PraiseSection";
import AboutSection from "./AboutSection";
import ContactSection from "./ContactSection";
import AuthorInterviewsSection from "./AuthorInterviewsSection";

const HomePageContent = () => {
    return (
        <main className="HomePageContent">
            <BuyBookSection />
            <PraiseSection />
            <AboutSection />
            <AuthorInterviewsSection />
            <ContactSection />
        </main>
    );
}

export default HomePageContent;
