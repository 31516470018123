import React from "react";
import AboutTheBookSection from "./AboutTheBookSection";

const OrderPageContent = () => {
    return (
        <main className="OrderPageContent">
            <AboutTheBookSection />
        </main>
    );
}

export default OrderPageContent;
