import React from "react";
import "./AuthorInterviewsSection.css";
import SectionHeader from "./SectionHeader";
import CprLogo from "./CprLogo.png";

const AuthorInterviewsSection = () => {
    return (
        <div className="AuthorInterviewsSection">
            <div className="interviewsHeaderContainer">
                <SectionHeader>Author Interviews</SectionHeader>
            </div>
            <div className="interviews">
                <div className="podcasts">
                    <div className="podcastWrapper">
                        <iframe
                            title="Former Denver Mayor Federico Pena New Book: Not Bad For A South Texas Boy - KOA"
                            height="175"
                            width="300"
                            src="https://www.iheart.com/podcast/1248-koa-newsradio-headlines-46108354/episode/11-29-21-former-denver-mayor-federico-pena-89853846/?embed=true&cid=oembed&keyid%25255B0%25255D=KOA%252520Headlines&keyid%25255B1%25255D=11-29-21%252520Former%252520Denver%252520Mayor%252520Federico%252520Pena%252520New%252520Book%25253A%252520Not%252520Bad%252520For%252520A%252520South%252520Texas%252520Boy%252520-%252520KOA%252520Headlines&sc=podcast_episode_widget&ihrnetwork=true"
                            allow="autoplay" frameBorder="0">
                        </iframe>
                    </div>
                    <div className="podcastWrapper">
                        <iframe
                            allow="autoplay *; encrypted-media *; fullscreen *"
                            title="Episode 71 - Federico Peña! - The Craig Silverman Show"
                            frameBorder="0"
                            height="175"
                            width="300"
                            sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
                            src="https://embed.podcasts.apple.com/us/podcast/episode-71-federico-pe%C3%B1a/id1522579679?i=1000542557978">
                        </iframe>
                    </div>
                    <div className="podcastWrapper">
                        <div className="cprPlayer">
                            <img className="cprLogo" src={CprLogo} alt="CPR News"/>
                            <a
                                className="cprLink"
                                href="https://www.cpr.org/show-segment/not-bad-for-a-south-texas-boy-the-making-of-federico-pena/">
                                ‘Not Bad For A South Texas Boy.’ The making of Federico Peña
                            </a>
                            <audio className="cprAudio" controls>
                                <source src="https://wp-cpr.s3.amazonaws.com/uploads/2022/01/220104-CM-WEB-Pena.mp3" type="audio/mpeg"/>
                            </audio>
                        </div>
                    </div>
                </div>
                <div className="videoContainer">
                    <iframe
                        src="https://www.youtube.com/embed/S-QGT1E5xW4"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen>
                    </iframe>
                </div>
            </div>
        </div>
    );
}

export default AuthorInterviewsSection;
