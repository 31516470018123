import React from "react";
import {
    NavLink
} from "react-router-dom";
import './NavBar.css';

const NavBar = () => {
    return (
        <header className="NavBar">
            <h1 className="name">Federico Peña</h1>
            <ul className="navLinks">
                <li className="navLink">
                    <NavLink to="/">
                        Home
                    </NavLink>
                </li>
                <li className="navLink">
                    <NavLink to="/order">
                        About the Book
                    </NavLink>
                </li>
            </ul>
        </header>
    );
}

export default NavBar;
