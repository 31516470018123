import { Routes, Route } from "react-router-dom";

import NavBar from "./NavBar";
import HomePageContent from "./HomePageContent";
import OrderPageContent from "./OrderPageContent";

import './App.css';

function App() {
  return (
    <div className="App">
      <NavBar />
        <Routes>
            <Route
                exact
                path="/"
                element={<HomePageContent />}
            />
            <Route
                exact
                path="/order"
                element={<OrderPageContent />}
            />
        </Routes>
    </div>
  );
}

export default App;
